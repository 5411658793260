import React, { useCallback, useEffect, useState } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button, InputGroup } from '@blueprintjs/core';
import isEmail from "validator/lib/isEmail";
import REQUEST_INVITE from './mutations/requestInvite';
import { useMutation } from '@apollo/client';
import './Homepage.css';
import { SteleToaster } from '../ui/Toaster/Toaster';
import { Link } from 'react-router-dom';
import CrowdwriteLogo from '../ui/svg/CrowdwriteLogo';

const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
}

const Homepage = ({

}) => {

    const [emailInput, setEmailInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [brushSize, setBrushSize] = useState(4);
    const [brushColor, setBrushColor] = useState('#e3d8d8');

    const onChangeEmail = (e) => {
        setEmailInput(e.target.value.trim());
    }
    const emailIsValid = isEmail(emailInput); 

    const [requestInvite, { loading: requestingInvite }] = useMutation(REQUEST_INVITE, {
        onError: (err) => {
            setIsLoading(false);
        },
        onCompleted: (response) => {
            setIsLoading(false);
            if (response && response.requestInvite && response.requestInvite.success === true) {
                setEmailInput('');
                SteleToaster.show({
                    className: 'dark',
                    icon: 'tick',
                    message: `Request sent`,
                    timeout: 5000
                })
            } else {
                SteleToaster.show({
                    message: `Sorry, that didn't work. Please try again later.`,
                    intent: 'danger',
                    timeout: 5000
                })
            }
        }
    });

    // const { executeRecaptcha } = useGoogleReCaptcha();
    const handleReCaptchaVerify = async () => {

        setIsLoading(true);

        if (!emailIsValid) {
            return;
        }

        // if (!executeRecaptcha) {
        //   return;
        // }
    
        // const responseToken = await executeRecaptcha('requestInvite');
        requestInvite({
            variables: {
                // responseToken: responseToken,
                email: emailInput
            }
        });

    };

    const onKeyDown = (e) => {
        // if (e.key === 'Enter' && emailIsValid) {
        //     handleReCaptchaVerify();
        // } 
    }

    return (
        <div className='Homepage' id='Homepage'
            style={{
                background: '#f2f2f0'
            }}
        >
            <div className='flex flex-col justify-center justify-between text-center h-screen py-2'>
                <div className='flex flex-col text-center'>
                    <div className='mx-auto opacity-80'>
                        <CrowdwriteLogo width={24} />
                    </div>
                   
                </div> 
                <p className='text-xl diatype -mt-8'>Write with everybody</p>
                <p className='opacity-0'>2025</p>
                {/* <p className='margin-bottom-0 font600'>Crowdwrite</p> */}
                {/* <p>
                    Request an invite
                </p>
                <div className='ProductStatus'>
                    <div className='RequestInvite'>
                        <InputGroup
                            large
                            placeholder='Enter your email'
                            value={emailInput}
                            onChange={onChangeEmail}
                            onKeyDown={onKeyDown}
                            className='mobile-fill margin-right-8 mobile-margin-bottom-12'
                        />
                        <Button large
                            type='submit'
                            intent='primary'
                            text={'Submit'}
                            disabled={!emailIsValid}
                            onClick={handleReCaptchaVerify}
                            loading={requestingInvite || isLoading}
                            className='mobile-fill'
                        />
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Homepage;