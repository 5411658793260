import { useQuery } from '@apollo/client';
import { Button, NonIdealState } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import SnippetTopMenu from '../SnippetEditor/SnippetTopMenu/SnippetTopMenu';
import DocIndexItem from './DocIndexItem/DocIndexItem';
import MY_DOCS from './queries/myDocs';
import './Dashboard.css';
import GlobalSpinner from '../ui/GlobalSpinner/GlobalSpinner';
import DocEditorModal from '../Doc/DocText/DocEditorModal/DocEditorModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { getRecentDocs } from '../../utils/localStorage';
import TextSelector from './TextSelector/TextSelector';

const Dashboard = (props) => {

    const navigate = useNavigate();
    const currentUser = useSelector(state => state.auth.user);

    const [showingEditModal, toggleEditModal] = useState(false);
    const onOpenEditor = () => toggleEditModal(true);
    const onCloseEditor = () => toggleEditModal(false);
    
    const onStartDoc = () => onOpenEditor();
   
    // if user is not logged in, redirect to homepage
    if (!currentUser || !currentUser._id) navigate('/');

    const { loading, error, data } = useQuery(MY_DOCS, {
        variables: {
            team: currentUser.username,
            docIDs: getRecentDocs()
        },
        fetchPolicy: 'cache-and-network'
    })

    if (loading) return <GlobalSpinner />;
    if (error) return `error`;

    const docs = data.allDocs.map((doc, i) => {
        return (
            <DocIndexItem
                key={i}
                title={doc.title}
                teamSlug={currentUser.username}
                created={doc.created}
                docID={doc.docID}
            />
        )
    })

    const recentDocs = data.recentDocs && data.recentDocs.length > 0 ? (
        data.recentDocs.filter((doc, i) => i < 6).map((doc, i) => {
            return (
                <DocIndexItem
                    key={i}
                    title={doc.title}
                    teamSlug={doc.team}
                    created={doc.created}
                    docID={doc.docID}
                />
            )
        })
    ) : null;

    return (
        <div className='mx-auto w-5/6 mt-20 pr-20 pl-16 mb-48'>
            <div className='margin-left-20'>
                <header className='flex gap-4 items-center mb-8'>
                    <h1>My Documents</h1>
                    <span>
                        <Button
                            intent='primary'
                            onClick={onStartDoc}
                        >
                            <div className='flex gap-1.5 items-center'>
                                <FontAwesomeIcon
                                    icon={faFile} 
                                    color="#fff"
                                    opacity={0.9}
                                />
                                <span>
                                    New
                                </span>
                            </div>     
                        </Button>
                    </span>
                </header>
                <div>
                    {recentDocs && (
                        <div className='mb-10'>
                            <h2 className='text-xl font-medium text-gray-500'>
                                Recent
                            </h2>
                            <div className='grid grid-cols-3 gap-2'>
                                {recentDocs}
                            </div>
                        </div>
                    )}

                    <h2 className='text-xl font-medium text-gray-500'>
                        All documents
                    </h2>
                    {docs.length > 0 && (
                        <div className='flex flex-col gap-2'>
                            {docs}
                        </div>
                    )}
                    {docs.length === 0 && (
                        <div className='grid grid-cols-3'>
                            <button
                                onClick={onStartDoc}
                                className='border border-gray-300 border-dashed hover:border-primary cursor-pointer rounded-xl shadow-sm px-6 py-8 hover:border-gray-300 hover:bg-gray-50 transition-all' 
                            >
                                <h3 className='text-lg font-semibold m-0 truncate ellipsis text-center text-deep-purple'>
                                    Start your first document
                                </h3>
                            </button>
                        </div>
                    )}
                </div>
                {currentUser && (
                    <DocEditorModal 
                        isOpen={showingEditModal}
                        docID={'NEW'}
                        teamID={currentUser._id}
                        teamSlug={currentUser.username}
                        userCanSave={true}
                        onClose={onCloseEditor}
                        text={null}
                        entityMap={null}
                        title={''}
                        userId={currentUser._id}
                    />
                )}
            </div>
        </div>
    )
}

export default Dashboard;