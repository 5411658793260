import { Button, Icon } from '@blueprintjs/core';
import React, { Fragment, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import CrowdwriteLogo from '../../ui/svg/CrowdwriteLogo';
import GhostIcon from '../../ui/svg/GhostIcon';
import AccountMenu from './AccountMenu/AccountMenu';
import './SnippetTopMenu.css';
import DocEditorModal from '../../Doc/DocText/DocEditorModal/DocEditorModal';
import { useSelector } from 'react-redux';
import TextButton from '../../ui/TextButton/TextButton';
import VerifyEmailBanner from './VerifyEmailBanner/VerifyEmailBanner';
import PositionedIcon from '../../ui/PositionedIcon/PositionedIcon';
import ViewToggle from './ViewToggle/ViewToggle';
import SourceViewMenu from './SourceViewMenu/SourceViewMenu';
import ReadViewMenu from './ReadViewMenu/ReadViewMenu';
import SideNav from '../../SideNav/SideNav';

const SnippetTopMenu = ({
    children
}) => {

    const currentUser = useSelector(state => state.auth.user);

    const { docID, teamSlug } = useParams();
    const location = useLocation();
    const isReadView = location.pathname.substring(location.pathname.length-5).includes('/read'); 

    const topMenuContext = useSelector(state => state.ui.topMenuContext);

    const [showingEditModal, toggleEditModal] = useState(false);
    const onShowEditor = () => toggleEditModal(true);
    const onCloseEditor = () => toggleEditModal(false);

    if (isReadView) {
        return (
            <ReadViewMenu
                currentUser={currentUser}
                onShowEditor={onShowEditor}
                teamSlug={teamSlug}
                docID={docID}
            >
                {children}
            </ReadViewMenu>
        )
    }

    return (
        <Fragment>
            <SourceViewMenu
                currentUser={currentUser}
                onShowEditor={onShowEditor}
                teamSlug={teamSlug}
                docID={docID}
                onCloseEditor={onCloseEditor}
                topMenuContext={topMenuContext}
                showingEditModal={showingEditModal}
            />
            {/* <SideNav /> */}
            {children}
        </Fragment>
    )
}

export default SnippetTopMenu;