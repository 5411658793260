import './App.css'; // needs to be at the top
import { Route, Routes } from 'react-router';
import { FocusStyleManager, HotkeysProvider } from "@blueprintjs/core";
import Join from './components/Join/Join';
import ScrollTopWrapper from './components/ui/ScrollTopWrapper/ScrollTopWrapper';
import Homepage from './components/Homepage/Homepage';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import SnippetDoc from './components/SnippetDoc/SnippetDoc';
import { AuthProvider } from './context/AuthContext';
import Dashboard from './components/Dashboard/Dashboard';
import Team from './components/Team/Team';
import Explore from './components/Explore/Explore';
import UserProfile from './components/UserProfile/UserProfile';
import ActivityPage from './components/ActivityPage/ActivityPage';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ResetPassword/ResetPassword';
import VerifyEmail from './components/VerifyEmail/VerifyEmail';
import GentleOnboardGate from './components/GentleOnboardGate/GentleOnboardGate';
import SignUpPage from './components/SignUpPage/SignUpPage';
import SignInPage from './components/SignInPage/SignInPage';
import SnippetTopMenu from './components/SnippetEditor/SnippetTopMenu/SnippetTopMenu';
import InviteHandler from './components/InviteHandler/InviteHandler';

function App() {

  FocusStyleManager.onlyShowFocusOnTabs();
  // posthog.init('phc_MZGzmEnVeTd5AJW0bh4or0ffaRjEtmLcc4UbT3sKQXn', { api_host: 'https://app.posthog.com' });

  return (
    <ScrollTopWrapper>
      <div className="App">
        <Routes>
          <Route path='/' element={(
            // <Navigate to={`/crowdwrite/cl8m0tbuf000509jxb2hde61e/text`} replace />
              <Homepage />
          )} />
        </Routes>
        <AuthProvider>
          <HotkeysProvider>
            <Routes>
              <Route path='/forgot' element={<ForgotPassword />} />
              <Route path='/reset-password/:token' element={<ResetPassword />} />
              <Route path='/sign-in' element={<SignInPage />} />
              <Route path='/sign-up' element={<SignUpPage />} />
              <Route path='/verify/:token' element={<VerifyEmail />} />
              <Route path='/join/:token' element={<Join />} />
              <Route path='/invite/:inviteID' element={<InviteHandler />} />
              <Route path='/docs' element={(
                <GentleOnboardGate>
                  <SnippetTopMenu>
                    <Dashboard />
                  </SnippetTopMenu>
                </GentleOnboardGate>
              )} />
              <Route path='/activity' element={<GentleOnboardGate><ActivityPage /></GentleOnboardGate>} />
              <Route path='/explore' element={<GentleOnboardGate><Explore /></GentleOnboardGate>} />
              <Route path='/user/:username/' element={(
                <GentleOnboardGate>
                  <SnippetTopMenu>
                    <UserProfile />
                  </SnippetTopMenu>
                </GentleOnboardGate>
              )} />
              <Route path='/:teamSlug/:docID/*' element={(
                <GentleOnboardGate>
                  <SnippetTopMenu>
                    <SnippetDoc />
                  </SnippetTopMenu>
                </GentleOnboardGate>
              )} />
              <Route path='/:teamSlug/' element={<Team />} />
            </Routes>
          </HotkeysProvider>
        </AuthProvider>
      </div>
    </ScrollTopWrapper>
  );
}

export default App;
